.testimonial-one img {
  display: unset !important;
  width: 90%;
}
.coinDetails-datepiker .picker__container {
  position: relative !important;
}
.wallet-card {
  margin: 0 auto;
  width: 95% !important;
}
.dataTables_wrapper .dataTables_paginate span .paginate_button {
  display: inline-block;
}
.sidebar-right
  .tab-content
  .tab-pane
  .admin-settings
  input[type="radio"]:checked
  + label::after {
  background-color: rgba(1, 1, 1, 0) !important;
}
/* ////////////////////////fixed */
.element-right-round button#dropdown-button-drop-dwon {
  border-radius: 0 0.75rem 0.75rem 0;
}
._i-hover:hover {
  left: 0 !important;
}
@media screen and (min-width: 695px) {
  .d-unset {
    display: unset !important;
  }
}
.d-unset {
  display: block;
}
button#check-minutes {
  border-radius: 0 0.75rem 0.75rem 0;
}
.fc-toolbar-chunk {
  display: flex;
}
@media screen and (max-width: 335px) {
  .fc-toolbar-chunk {
    display: block;
    text-align: center;
    line-height: 2.8;
  }
  .fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
    margin: 0 !important;
  }
}
/* Editor */
.tox .tox-notification--warn,
.tox .tox-notification--warning {
  display: none !important;
}

/* Map */
svg.svg-map {
  stroke: white !important;
}
.svg-map__location {
  fill: #eff2f4 !important;
}
.svg-map__location:focus,
.svg-map__location:hover {
  fill: black !important;
}

.p-meddle {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
}
/* color picker */

.chrome-picker {
  position: absolute;
  top: -310px;
}
.color-gradian-tixia .popover {
  top: -410px !important;
}
/* wizard */

/* date piker */
@media screen and (max-width: 590px) {
  .rangeDatePicker .calendar__item {
    display: block !important;
    width: 100%;
  }
  .rangeDatePicker .calendar__container {
    width: 100% !important;
  }
}

form#step-form-horizontal div:first-child > div:last-child {
  text-align: right;
  /* padding-top: 2rem; */
}

label.mailclinet {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  cursor: pointer;
  background-color: #eef5f9;
  text-align: center;
  margin: auto;
}

.wizard-four-color {
  color: #222fb9;
  text-decoration: underline;
}

.redio-false {
  display: none;
}

span.mail-icon {
  font-size: 3rem;
  display: inline-block;
  line-height: 1;
  margin-top: -1rem;
}

span.mail-text {
  font-size: 1rem;
  text-align: center;
  margin-top: 0.5rem;
}

/* Wizard end */

/* cursor-pointer */
.cursor-pointer {
  cursor: pointer;
}
/* form */
#multiselect button.btn.dropdown-toggle.btn-light {
  background: no-repeat;
  border: 1px solid #d7dae3;
  color: #3e4954;
  font-weight: 100;
}
/* slider */

.card-text {
  margin-bottom: 0;
}
#whiteSpace {
  white-space: normal;
}

#tp-btn::after {
  content: "";
}

.swal-footer {
  text-align: center !important;
  margin: 0 !important;
}

.ui .progress .progress-bar {
  animation-name: animateBar;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.6s;
}
@keyframes animateBar {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
ol.go2441762052 {
  text-align: center;
}

#order2 {
  order: 2;
}

/* Editor */

.summernote > :first-child {
  min-height: 250px;
  border: 1px solid #a9a9a9;
  border-color: #f0f1f5;
}
.rdw-image-modal {
  right: 0 !important;
  left: unset !important;
}
.rdw-editor-toolbar {
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
}

/* Button */
button:focus,
a:focus {
  outline: none !important;
  box-shadow: none !important;
}

/* Chart */

.go2478912419 {
  text-align: center;
  display: block;
  margin-bottom: 2rem;
}

.picker-input__text,
.range-picker-input {
  width: 100% !important;
}

.timepicker-container,
.MuiFormControl-root.MuiTextField-root {
  flex: 1 1 auto;
}

.timepicker-container input {
  background: #fff;
  border: 1px solid #f0f1f5;
  color: #b1b1b1;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  height: 41px;
  width: 100%;
  box-sizing: border-box;
}

.MuiFormControl-root.MuiTextField-root {
  display: block;
}
.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
  width: 100%;
  height: 100%;
}

.dd .nestable-item-name {
  color: #fff;
  background: #216fed;
  display: block;
  height: 35px;
  margin: 5px 0;
  padding: 8px 10px;
  text-decoration: none;
  font-weight: 700;
  border: 1px solid #216fed;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 13px;
  margin-bottom: -4px;
}

/* Picker */

.i-false:after {
  content: none !important;
}

rect#chart_ChartBorder {
  fill: transparent;
}

/* pagination */
.pagination {
  margin-bottom: 0 !important;
}
@import url("https://fonts.googleapis.com/css?family=Lato");
.rc-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 80;
}
.rc-backdrop.invert {
  background: rgba(1, 1, 1, 0.7);
}
.range-picker-input {
  display: inline-flex;
  border: 1px solid #e4e4e4;
  width: 300px;
}
.range-picker-input * {
  box-sizing: border-box;
}
.range-picker-input__icon {
  display: inline-flex;
  align-items: center;
}
.range-picker-input__start,
.range-picker-input__end {
  display: inline-flex;
  flex: 1;
}
.range-picker-input__start .picker-input.range input,
.range-picker-input__end .picker-input.range input {
  width: 100%;
  border: none;
}
.picker-input {
  display: inline-block;
  position: relative;
}
.picker-input__icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  display: inline-flex;
  align-items: center;
}
.picker-input__text {
  padding: 10px;
  border: 1px solid #e4e4e4;
  outline: none;
  font-size: 16.8px;
}
.picker-input__text:disabled {
  background: #e4e4e4;
}
.picker-input__clear {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  cursor: pointer;
}
.picker {
  display: inline-block;
}
.picker__container {
  position: absolute;
  z-index: 100;
}
.picker__container.portal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.picker__container__include-time {
  border: 1px solid #e4e4e4;
}
.picker__container__include-time .calendar__item,
.picker__container__include-time .time__container {
  border: none;
}
.picker__container__tab {
  margin: 10px 0;
}
.picker__container__tab button {
  padding: 5px 10px;
  outline: none;
  display: inline-flex;
  align-items: center;
  background: none;
  border: none;
  border-bottom: 2px solid #e4e4e4;
}
.picker__container__tab button.active {
  color: #49599a;
  border-bottom: 2px solid #49599a;
}
.picker__container__tab button:first-child {
  border-right: none;
}
.picker__container__tab button svg {
  margin-right: 5px;
}
.time__container {
  display: inline-flex;
  align-items: center;
  border: 1px solid #e4e4e4;
  padding: 15px;
  background: white;
  font-family: "Lato";
  font-size: 12px;
}
.time__container__div {
  margin: 0 10px;
}
.time__container__type {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.time-input {
  display: inline-block;
  width: 40px;
  overflow: hidden;
}
.time-input__up,
.time-input__down {
  border: 1px solid #e4e4e4;
}
.time-input__up button,
.time-input__down button {
  outline: none;
  width: 100%;
  cursor: pointer;
  border: none;
}
.time-input__text {
  width: 100%;
  border-left: 1px solid #e4e4e4;
  border-right: 1px solid #e4e4e4;
  box-sizing: border-box;
}
.time-input__text input {
  width: 100%;
  box-sizing: border-box;
  border: none;
  font-size: 15px;
  padding: 5px;
  text-align: center;
  outline: none;
}

/* calender */

.react-calendar {
  border: none !important;
  font-family: "poppins", sans-serif !important;
  width: 100% !important;
  font-size: 20px;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: none !important;
}
button.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
  color: #dee2e6 !important;
  font-family: "poppins", sans-serif;
}

span.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
  font-size: 20px;
  font-weight: 500;
  color: #000;
  font-family: "poppins", sans-serif;
}
button.react-calendar__navigation__arrow.react-calendar__navigation__prev-button,
button.react-calendar__navigation__arrow.react-calendar__navigation__next-button {
  display: none;
}

button.react-calendar__tile.react-calendar__month-view__days__day {
  border-radius: 10px;
  color: black;
  color: #000;
  font-weight: 400;
  height: 55px;
  line-height: 30px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "poppins", sans-serif;
}
button.react-calendar__navigation__label {
  /* padding: 20px 15px; */
  border-radius: 1.75rem !important;
  font-size: 20px;
}
button.react-calendar__navigation__label:hover {
  color: white !important;
  background: #eeeeee !important;
}
button.react-calendar__navigation__label:hover > span {
  /* color: white !important; */
  /* background: #EEEEEE !important; */
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus,
.react-calendar__navigation__label:hover > span {
  /* background-color: #eeeeee !important; */
  /* color: white; */
  /* border-radius: 50%; */
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus,
.react-calendar__tile--hasActive,
.react-calendar__navigation button[disabled],
.react-calendar__navigation button[disabled] > span {
  background-color: #eeeeee !important;

  /* color: white; */
}
button.react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day,
button.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
  position: relative;
  box-shadow: none;
  /* color: white !important; */
  border-radius: 10px;
  overflow: unset !important;
  padding: 16px 5px 15px 5px;
  /* border-radius: 35px; */
  line-height: 1;
  color: #fff;
  background: var(--primary) !important;
}
button.react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day::before,
button.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day::before {
  content: "";
  height: 1.4rem;
  width: 1.4rem;
  border-radius: 1.4rem;
  right: -0.3125rem;
  top: -0.3125rem;
  position: absolute;
  border: 3px solid #fff;
  background: #ffc368;
}

.react-calendar {
  border: none;
}
.react-calendar__navigation button {
  font-size: 40px;
}
.calendar {
  display: inline-block;
  background: white;
  font-size: 12px;
}
.calendar *,
.calendar *:before,
.calendar *:after {
  box-sizing: border-box;
}
.calendar__container {
  width: 270px;
  font-family: "Roboto", sans-serif;
  display: none;
}
.calendar__list {
  display: table;
}
.calendar__item {
  display: table-cell;
  border: 1px solid #ececec;
}
.calendar__item:not(:first-child) {
  border-left: none !important;
}
.calendar--show {
  display: inline-block;
}
.calendar__head {
  position: relative;
  background: transparent;
  padding: 10px 6px;
}
.calendar__head--title {
  font-size: 1.3em;
  color: #9a9fa4;
  text-align: center;
  margin: 4px;
}
.calendar__head--button {
  outline: none;
  border: none;
  cursor: pointer;
  background: none;
  font-size: 20px;
}
.calendar__head--button svg {
  fill: #9a9fa4;
}
.calendar__head--prev,
.calendar__head--next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}
.calendar__head--prev {
  left: 0;
}
.calendar__head--next {
  right: 0;
}
.calendar__panel--show {
  display: block !important;
}
.calendar__panel--today {
  background: #aab6fe;
  padding: 5px;
  display: none;
}
.calendar__panel--today h2 {
  margin: 0;
  cursor: pointer;
  font-size: 12px;
  text-align: center;
}
.calendar__body--table {
  width: 100%;
  table-layout: fixed;
  text-align: center;
  border-spacing: none;
  border-collapse: collapse;
}
.calendar__body--table th {
  height: 30px;
  vertical-align: middle;
  color: #9a9fa4;
}
.calendar__day {
  vertical-align: top;
  padding-top: 5px;
  height: 40px;
  cursor: pointer;
}
/* .calendar__day self:hover:not(&--disabled) {
   background: #aab6fe;
   cursor: pointer;
} */
.calendar__day--0 {
  color: red;
}
.calendar__day--6 {
  color: blue;
}
.calendar__day--today {
  background: #fff9c4;
}
.calendar__day--disabled {
  color: #ddd;
  cursor: initial;
}
.calendar__day--start,
.calendar__day--end,
.calendar__day--selected {
  background: #7986cb;
  color: #fff;
}
.calendar__day--start:hover,
.calendar__day--end:hover,
.calendar__day--selected:hover {
  background: #7986cb;
}
.calendar__day--range {
  background: #dce1ff;
}
.calendar__day--text {
  display: block;
  font-size: 10px;
}
.calendar__year,
.calendar__month {
  height: 55px;
  vertical-align: middle;
}
.calendar__year:hover,
.calendar__month:hover {
  background: #aab6fe;
  cursor: pointer;
}

/* Selector Design */
.css-1okebmr-indicatorSeparator {
  display: none;
}

.nestable .icon-minus-gray,
.nestable .icon-plus-gray {
  background-image: none;
}
.nestable .nestable-icon:before {
  content: "\f068";
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  width: auto;
  height: auto;
  display: contents;
}
.nestable .nestable-item--children-collapsed .nestable-icon:before {
  content: "\f067";
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  width: auto;
  height: auto;
  display: contents;
}

/* table css */
.form-control.input-search {
  height: 38px;
  border-radius: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
}
.input-search {
  height: 38px;
  border-radius: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
  border: 1px solid #eee;
  padding: 0 8px;
}

.filtering-table thead th:first-child {
  width: 70px;
}
.table tfoot tr {
  border-bottom-width: 1px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  border-color: #eeeeee;
}

.filter-pagination {
  display: inline-block;
  background: #f0f0f0;
  border-radius: 10px;
  padding: 5px 5px;
}
.filter-pagination .previous-button,
.filter-pagination .next-button {
  font-size: 16px;
  border: 0 !important;
  width: auto;
  padding: 14px 20px;
  margin: 0 5px;
  border-radius: 10px;
  display: inline-block;
  background-color: #fff;
  color: #216fed;
}
.filter-pagination button:hover {
  background: #216fed;
  color: #fff;
}
.filter-pagination button[disabled] {
  opacity: 0.5;
  cursor: default;
}
.filter-pagination button[disabled]:hover {
  background: #216fed;
  opacity: 0.5;
  color: #fff;
}
.table-index input[type="number"] {
  background: #f0f0f0 !important;
  color: #969ba0;
  width: 70px;
  border: 0;
  background-color: #f8f8f8;
  padding: 6px 0 6px 10px;
}
@media only screen and (max-width: 575px) {
  .filter-pagination .previous-button,
  .filter-pagination .next-button {
    padding: 8px 10px;
    font-size: 14px;
  }
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

@media screen and (max-width: 992px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 576px) {
  .grid-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

.grid-item {
  width: 100%;
  height: 100%;
  min-height: 20rem;
  min-width: 26em;
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: #fff;
  padding: 1rem;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  transition: all 0.3s ease-in-out;
  position: relative;
}

.grid-item:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 2;
}

/* .grid-item:hover ~ .grid-item, .grid-item ~ .grid-item:hover {
  opacity: 0.3s;
  transition: all 0.3s ease-in-out;
}

.grid-item:hover, .grid-item:hover ~ .grid-item:hover {
  opacity: 1;
  transition: all 0.3s ease-in-out;
} */

@media screen and (max-width: 992px) {
  .grid-item {
    width: 80%;
    height: 100%;
    min-width: 26em;
    background-color: #fff;
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 576px) {
  .grid-item {
    width: 100%;
    height: 100%;
    min-width: 26em;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

tr.grid-item td {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

}
.actorList-bx {
  display: flex;
  align-items: center;
  min-height: 12rem;
  width: 100%;
  /* box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1); */
  /* border:1px solid rgba(0, 0, 0, 0.1) */
}
.actorList-bx img {
  width: 100%;
  height: 12rem;
  min-width: 20rem;
  min-height: 12rem;
  max-height: 12rem;
  object-fit: contain;
  border-radius: 0.625rem;
}
.actorList-bx .carousel {
  width: 100%;
  height: 12rem;
  min-height: 12rem;
  max-height: 12rem;
  border-radius: 0.625rem;
}
.actorList-bx .carousel .carousel-control-prev,
.actorList-bx .carousel .carousel-control-next {
  height: 2.375rem;
  width: 2.375rem;
  background: #fff;
  margin: 0 0.25rem;
  top: 50%;
  border-radius: 50%;
  transform: translateY(-50%);
}

.customCheckbox {
  display: none;
}

.customCheckboxLabel {
  display: inline-block;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #e5e5e5;
  border: 2px solid #c4c4c4;
  box-shadow: inset 0 1px 3px rgba(0,0,0,.1);
  transition: background-color .2s ease-in-out;
  cursor: pointer;
}
.customCheckboxLabel:before {
  content: "\2714";
  color: transparent;
  text-shadow: 0 0 0 #fff;
  transition: color .2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;

}

.customCheckbox:checked + .customCheckboxLabel {
  background-color: #5b94c5;
  border-color: #5b94c5;
  box-shadow: none;
  /* background-image: url('f00c'); */
  background-repeat: no-repeat;
  background-position: center;
}

.customCheckbox:focus + .customCheckboxLabel {
  outline: none;
  border-color: #5b94c5;
}

.filter-container{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.filter-item{
  flex-basis: 16.67%;
  flex-grow: 1;
  padding: 5px;
}

.feature ,.unfeature{
  transition: all 0.3s ease-in-out;
}

.unfeature:hover{
  color:#FFC368 !important
}
.feature:hover{
  color: #89879f  !important;
}

.grid-container-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

@media screen and (max-width: 992px) {
  .grid-container-3 {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 576px) {
  .grid-container-3 {
    grid-template-columns: repeat(3, 1fr);
  }
}

.small-grid-item {
  /* width: 100%;
  height: 100%; */
  min-height: 3rem;
  min-width: 3em;
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: #fff;
  padding: 1rem;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  transition: all 0.3s ease-in-out;
  position: relative;
}

.small-grid-item:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 2;
}

@media screen and (max-width: 992px) {
  .small-grid-item {
    width: 80%;
    height: 100%;
    min-width: 3em;
    background-color: #fff;
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 576px) {
  .small-grid-item {
    width: 100%;
    height: 100%;
    min-width: 3em;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.scroll-y {
  overflow-y: scroll;
  height: 100%;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.preloader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;

}
.preloader img {
  width: 100%;
  height: 100%;
}
* {
  text-transform: capitalize !important;
}
.rc-slider-mark{
  display: none;
}